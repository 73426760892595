<template>
  <div style="height: auto;">
    <v-dialog v-model="imgdialog" width="600px">
                <v-toolbar text color="#00A3A3" class="" dark dense>
              <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"> <span style="font-size:15px;">TIME:{{rtime}}</span>&nbsp;&nbsp;<span style="font-size:15px;">BAC: {{bacval}}</span></v-toolbar-title>
              <v-toolbar-title v-else> <span style="font-size:11px;">TIME:{{rtime}}</span>&nbsp;<span style="font-size:11px;">BAC: {{bacval}}</span></v-toolbar-title>
              <v-layout justify-end>
                <!-- <v-icon v-if="imageBytes != ''" @click="downloadImage(currentevent)" color="white" class="mr-4"  style="background:black;padding:4px;border-radius:12px">mdi-download</v-icon> -->
              
                <!-- <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="white"  class="mr-4" style="background:black;padding:4px;border-radius:12px">mdi-fullscreen</v-icon> -->
              
              <v-btn color="black" small @click="imgclose" dark style="border-radius:20px;" ><v-icon color="white">
            mdi-close
          </v-icon></v-btn>
              </v-layout>
                </v-toolbar>
                <img id="my-video"  v-bind:src="'data:image/jpeg;base64,'+imageBytes" v-if="imageBytes != ''"/> 
                 <v-card-text v-else class=text-center>
                <h2 style="background:black;color:white;padding:5px;border-radius:20px">Image Not Found</h2>
                </v-card-text>
                <v-toolbar text color="#00A3A3" class="" dark dense>
                  <v-toolbar-title>
                    <span style="font-size:16px;">RESULT: {{result}}</span>
                  </v-toolbar-title>
                </v-toolbar>
              </v-dialog>
      <!-- <v-card
            class="mt-6 mx-auto" style="margin-top:10%;border-radius:20px;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);"> -->
            <!-- <v-sheet
              class="v-sheet--offset mx-auto"
              color="#00A3A3"
              elevation="12"
              max-width="calc(100% - 32px)" style="top: -30px;
            position: relative;"
            > -->
    <canvas ref="barChart" height="300px" width="400px" ></canvas>
    <!-- </v-sheet> -->
    <!-- </v-card> -->

  </div>
</template>

<script>
import Chart from 'chart.js';
import Vue from "vue";
import moment from 'moment';

export default {
  name: "alcoholInfo",
props: ["vdata","sdata"],
  data() {
    return {
      labels:[],
      datas:[],
      bgcolors: [],
      bacs:[],
      bacval:null,
      imageBytes:'',
      imgdialog:false,
      rtime:null,
      result:'NA'
    };
  },
  beforeMount(){
    console.log(this.sdata)

  },
  mounted() {
    this.renderChart();
  },
  methods: {
    imgclose(){
      this.bacval=null;
      this.imageBytes='';
      this.imgdialog=false;
      this.rtime=null;
      this.result='NA';
    },
    onOpenimage(event, chartElements) {
      // const clickedBarIndex = chartElements[0];
      const clickedBarIndexval = chartElements[0]._index;
        this.rtime = chartElements[0]._model.label;
        this.bacval = this.bacs[clickedBarIndexval];
        this.result = parseInt(this.bacs[clickedBarIndexval])<20?"PASS":"FAIL";
      var itemname = chartElements[0]._model.label;
      var stname = itemname.replaceAll("/", "_");
      var ssname = stname.replaceAll(" ", "_");
      var smname = ssname.replaceAll(":", "_");
      Vue.axios
        .get(
          "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
            this.vdata.cbid +
            "&Image=" +
           itemname
        )
        .then((res) => {
          // check Image at exact time
          if(res.data){
          this.imageBytes = res.data;
          } else{
            
           this.$store.dispatch("alert/error", "No Image Found").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
  })
      
        // End second check
          }
        })
        .catch((error) => {
           this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        });
      // if (chartElements.length > 0) {
      //   const clickedBarIndex = chartElements[0]._index;
      //   const clickedBarLabel = this.data.labels[clickedBarIndex];
      //   console.log(`Clicked on bar with label: ${clickedBarLabel}`);
      //   // Add your custom logic here
      // }
    },
    renderChart() {
      var myPromise = new Promise(async(res,rej)=>{
        if(this.vdata.cbid){
    await Vue.axios
      .get(
        "https://robopower.xyz/v2/eeprom/gettenlogs?cbid=" +
          this.vdata.cbid
      )
      .then(response => {
console.log(response.data);
var eventsdata =  response.data;
eventsdata.map((res) => {
              if (res.typeofevent == "254") {
                this.labels.push(res.rtime)
                this.datas.push(10);
                this.bacs.push(res.bacvalue)
                if (parseFloat(res.bacvalue) >= 20) {
                  this.bgcolors.push('red');
                } else if (parseFloat(res.bacvalue) < 20 && parseFloat(res.bacvalue) > 0) {
                  this.bgcolors.push('orange');
                } else {
                  this.bgcolors.push('green');
                }
              }
            })
      
    })
    res({"status":"ok"});
        } else{
          rej("Device Not Found")
        }
      })
      myPromise.then((res)=>{
        console.log(res)
        var ctx = this.$refs.barChart.getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
        labels: this.labels,
        datasets: [
          {
            barPercentage: 0.6,
            categoryPercentage: 0.5,
            label: "Vehicle:"+ this.sdata.regnum,
            backgroundColor: this.bgcolors,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            data: this.datas,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            
            gridLines: {
              display: false
            },
            ticks: {
                maxRotation: 45,
                minRotation: 45,
              }
          },
          ],
          yAxes: [{
            stacked: true,
            display:false,
            gridLines: {
              display: false
            }
          }]

        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onOpenimage
}
      });
      })

    },
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
</style>
